/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import cx from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useEffect } from 'react';
import Image from '@common_image';
import Typography from '@common_typography';
import config, { headerVersion } from '@config';

import Button from '@common_button';
import { useRouter } from 'next/router';

// import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
// import MagnifyingGlassIcon from '@heroicons/react/24/outline/MagnifyingGlassIcon';
import FossilIcon from '@core_modules/theme/plugins/fossilicon';

import Drawer from '@common_drawer';
import Tabs from '@common_tabs';
import useMediaQuery from '@core/hooks/useMediaQuery';

const Autocomplete = dynamic(() => import('@core_modules/theme/components/header/components/autocomplete'), { ssr: true });
const Menu = dynamic(() => import('@core_modules/theme/components/header/components/v1/mcategory'), { ssr: true });
const ProductCompareIcon = dynamic(() => import('@core_modules/catalog/plugins/ProductCompare'), { ssr: true });
const ShoppingBagIcon = dynamic(() => import('@plugin_shoppingbag'), { ssr: true });
// const NotificationBell = dynamic(() => import('@plugin_notificationbell'), { ssr: true });
const UserInfo = dynamic(() => import('@core_modules/theme/components/header/components/v1/adaptive/plugin/userinfo'), { ssr: false });
const BurgerMenuCategories = dynamic(() => import('@core_modules/theme/components/header/components/burgermenu/categories'), { ssr: false });
const BurgerMenuAccount = dynamic(() => import('@core_modules/theme/components/header/components/burgermenu/account'), { ssr: false });

const initialOpenState = {
    isAccountOpen: false,
    isCartOpen: false,
};

const openReducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_ACCOUNT':
            return { isCartOpen: false, isAccountOpen: true };
        case 'OPEN_CART':
            return { isCartOpen: true, isAccountOpen: false };
        case 'CLOSE_ACCOUNT':
            return { ...state, isAccountOpen: false };
        case 'CLOSE_CART':
            return { ...state, isCartOpen: false };
        default:
            return state;
    }
};

const DesktopHeader = (props) => {
    const {
        //
        t,
        storeConfig,
        isLogin,
        customer,
        setValue,
        handleSearch,
        dataMenu,
        loadingMenu,
        handleLogout,
        deviceWidth,
        cmsMenu,
        ...other
    } = props;
    const { modules } = config;
    const { isDesktop, isMobile } = useMediaQuery();
    const isHeaderV2 = headerVersion === 'v2';

    const logoDimensions = {
        width: storeConfig?.logo_width || (isDesktop ? 120 : 74),
        height: storeConfig?.logo_height || (isDesktop ? 52 : 34),
    };
    const logoAdditionalProps = {};
    if (!isMobile) {
        logoAdditionalProps.styleContainer = {
            width: `${logoDimensions.width}px`,
            height: `${logoDimensions.height}px`,
            paddingTop: 0,
        };
    }

    const [open, setOpen] = React.useState(false);
    const [openBurgerMenu, setOpenBurgerMenu] = React.useState(false);
    const [localOpen, setLocalOpen] = React.useState(false);
    const [isSearchShown, setIsSearchShown] = React.useState(false);

    const [openState, dispatch] = React.useReducer(openReducer, initialOpenState);

    const [isZeroCompare, setIsZeroCompare] = React.useState(false);

    const handleClose = () => {
        setLocalOpen(false);
        setTimeout(() => {
            setOpenBurgerMenu(false);
        }, 500);
    };

    // Close drawer menu after move to other page
    const router = useRouter();
    useEffect(() => {
        if (openBurgerMenu && !isDesktop) {
            setOpenBurgerMenu(false);
        }
    }, [router]);

    useEffect(() => {
        setLocalOpen(openBurgerMenu);
    }, [openBurgerMenu]);

    const filteredData = dataMenu?.categories?.items[0]?.children.filter((item) => item.include_in_menu !== 0);
    const burgerMenuData = [
        {
            title: 'Menu',
            content: dataMenu && <BurgerMenuCategories data={filteredData} cmsMenu={cmsMenu} />,
            type: 'react-component',
        },
        {
            title: 'Account',
            content: <BurgerMenuAccount isLogin={isLogin} handleLogout={handleLogout} {...other} />,
            type: 'react-component',
        },
    ];

    const PopoverContent = () => {
        return (
            <div
                onMouseLeave={() => dispatch({ type: 'CLOSE_ACCOUNT' })}
                className={cx(
                    'py-[20px]', 'px-0', 'min-h-fit', 'max-w-[308px]', '!rounded-[5px]', 'popover-active',
                    'w-[208px]',
                )}
            >
                <ul className={cx('my-account-list__wrapper')}>
                    <li
                        key={0}
                        className={cx(
                            'my-account-list__item',
                            'py-[6px]',
                            'px-[30px]',
                            'text-left',
                            'hover:cursor-pointer',
                            'hover:bg-primary-50',
                        )}
                    >
                        <Link href="/customer/account">
                            <Typography
                                className={cx(
                                    'currency-list__text !text-neutral-black !text-[15px] font-medium leading-[22px]',
                                )}
                            >
                                {t('common:header:myAccount')}
                            </Typography>
                        </Link>
                    </li>
                    <li
                        key={1}
                        className={cx(
                            'my-account-list__item',
                            'py-[6px]',
                            'px-[30px]',
                            'text-left',
                            'hover:cursor-pointer',
                            'hover:bg-primary-50',
                        )}
                    >
                        <Link href="/sales/order/history">
                            <Typography
                                className={cx(
                                    'currency-list__text !text-neutral-black !text-[15px] font-medium leading-[22px]',
                                )}
                            >
                                {t('common:header:orderHistory')}
                            </Typography>
                        </Link>
                    </li>
                    <li
                        key={2}
                        className={cx(
                            'my-account-list__item',
                            'py-[6px]',
                            'px-[30px]',
                            'text-left',
                            'hover:cursor-pointer',
                            'hover:bg-primary-50',
                        )}
                    >
                        <Link href="/wishlist">
                            <Typography
                                className={cx(
                                    'currency-list__text !text-neutral-black !text-[15px] font-medium leading-[22px]',
                                )}
                            >
                                {t('common:header:wishlist')}
                            </Typography>
                        </Link>
                    </li>
                    <li
                        key={3}
                        className={cx(
                        'my-account-list__item',
                        'py-[6px]',
                        'px-[30px]',
                        'text-left',
                        'hover:cursor-pointer',
                        'hover:bg-primary-50',
                    )}
                    >
                        <Link href="/catalog/product_compare">
                            <Typography
                                className={cx(
                                    'currency-list__text !text-neutral-black !text-[15px] font-medium leading-[22px]',
                                )}
                            >
                                {t('common:header:compareProduct')}
                            </Typography>
                        </Link>
                    </li>
                    <li
                        key={4}
                        className={cx(
                            'my-account-list__item',
                            'py-[6px]',
                            'px-[30px]',
                            'text-left',
                            'hover:cursor-pointer',
                            'hover:bg-primary-50',
                        )}
                        onClick={() => handleLogout()}
                    >
                        <Typography
                            className={cx(
                                    'currency-list__text !text-neutral-black !text-[15px] font-medium leading-[22px]',
                                )}
                        >
                            {t('common:header:logout')}
                        </Typography>
                    </li>
                </ul>
            </div>
        );
    };

    const SearchIcon = (
        <FossilIcon
            fontSize={22}
            width={24}
            height={23}
            iconContent='"\e959"'
        />
    );
    const hideProductCompare = false;
    const burgerIcon = (
        <FossilIcon
            fontSize={28}
            width={28}
            height={40}
            iconContent='"\e609"'
        />
    );

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            const middleHeaderLogo = document.querySelector('.middle-header__logo');
            if (isSearchShown) {
                middleHeaderLogo.style.position = 'relative';
            } else {
                middleHeaderLogo.style.position = '';
            }
        }
    }, [isSearchShown]);

    return (
        <div
            className={cx(
                'desktop-header', 'transition-transform', 'delay-300', 'duration-500', 'ease-in-out',
                ' relative after:border-b after:border-neutral-200 after:w-full after:absolute after:bottom-0',
            )}
        >
            <div className={cx('middle-header', 'tablet:py-0')}>
                <div
                    className={cx(
                        'middle-header__wrapper relative',
                        '!max-w-[1320px]',
                        'm-[0_auto]',
                        'flex',
                        'flex-row',
                        'justify-between',
                        'mobile:px-2.5',
                        'desktop:flex',
                        'desktop:justify-between',
                        'tablet:max-desktop:min-h-[83px]',
                        'tablet:max-desktop:items-center',
                        'mobile:max-tablet:min-h-[50px]',
                    )}
                >
                    <div
                        className={cx(
                            'flex flex-row gap-4 desktop:w-full tablet:max-desktop:gap-x-[21px] mobile:max-tablet:items-center',
                            'mobile:max-tablet:w-full tablet:max-desktop:w-full',
                        )}
                    >
                        {!isDesktop ? (
                            <div className={cx('middle-header-tablet__burger-menu', 'desktop:hidden')}>
                                <Button
                                    className={cx(
                                        'mt-[2px]',
                                        'mb-2',
                                        '!p-0',
                                        '!mx-0',
                                        'hover:shadow-none',
                                        'focus:shadow-none',
                                        'active:shadow-none',
                                        'active:shadow-none',
                                        'mobile:max-tablet:mt-1',
                                    )}
                                    onClick={() => {
                                        setOpenBurgerMenu(true);
                                    }}
                                    icon={burgerIcon}
                                    iconProps={{ className: cx('text-neutral-700', 'w-[24px]', 'h-[24px]') }}
                                    iconOnly
                                    variant="tertiary"
                                    classNameText={cx('!text-neutral-700')}
                                    aria-label="burger-menu"
                                />
                                {dataMenu && openBurgerMenu && (
                                    <Drawer
                                        open={localOpen}
                                        handleClose={handleClose}
                                        position="left"
                                        className={cx('mobile:max-tablet:w-[280px]', 'tablet:max-desktop:w-[396px]', 'desktop:w-[540px]', {
                                            'animate-hamburger-drawer-in': localOpen,
                                            'animate-hamburger-drawer-out': !localOpen,
                                        })}
                                        customButtonClose
                                        backdrop
                                    >
                                        <Tabs
                                            data={burgerMenuData}
                                            tabHasContent
                                            tabWrapperClassName={cx('border-none')}
                                            tabTitleWrapperClassName={cx('grid', 'grid-cols-2')}
                                            tabTitleClassName={cx('border-none', '!text-neutral-700', 'text-2md', 'font-semibold')}
                                            tabTitleActiveClassName={cx('border-none', '!text-neutral-700', 'text-2md', 'font-semibold')}
                                            tabTitleListClassName={cx('bg-neutral-100')}
                                            tabTitleListActiveClassName={cx('bg-neutral-white', 'border-b-[1px]', 'border-b-neutral-400')}
                                            tabContentClassName={cx('!pt-0', 'h-full', 'overflow-y-auto', 'overflow-x-hidden')}
                                        />
                                    </Drawer>
                                )}
                            </div>
                        ) : null}
                        {!isDesktop ? (
                            <div className="tablet:hidden mobile:ml-[5px]">
                                <Link href="/customer/account">
                                    <FossilIcon className="" iconContent='"\e953"' fontSize={28} width={28} height={28} />
                                </Link>
                            </div>
                        ) : null}
                        <div
                            className={cx(
                                'middle-header__logo',
                                'desktop:basis-full',
                                'relative',
                                'tablet:flex',
                                'items-center',
                                'cursor-pointer',
                                'mobile:max-tablet:w-[74px]',
                                'mobile:max-tablet:mt-2',
                                'mobile:max-tablet:relative',
                                'desktop:justify-between',
                                'mobile:max-tablet:mt-0',
                                'mobile:max-tablet:left-[calc(46%-1px)]',
                                'mobile:max-tablet:translate-x-[-100%]',
                                'mobile:max-tablet:translate-y-[0]',
                                'mobile:max-tablet:mt-[2px]',
                                'mobile:max-tablet:min-w-[80px]',
                                'tablet:max-desktop:w-full',
                                '[position:unset]',
                            )}
                        >
                            <Link href="/">
                                <Image
                                    className="header-middle__logo-link"
                                    src={`${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`}
                                    alt={storeConfig.default_title}
                                    {...logoDimensions}
                                    {...logoAdditionalProps}
                                    storeConfig={storeConfig}
                                />
                            </Link>
                            <div className="flex flex-row menu-category mobile:max-desktop:hidden">
                                <div className="max-w-full">
                                    <div className="xs:basis-full menu-middle w-max">
                                        <nav className={cx('menu-wrapper')} role="navigation">
                                            {loadingMenu
                                                ? <></>
                                                : (
                                                    <Menu
                                                        useOthers
                                                        t={t}
                                                        data={dataMenu}
                                                        cmsMenu={cmsMenu}
                                                        storeConfig={storeConfig}
                                                    />
                                            )}
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            {isSearchShown && !isMobile ? (
                                <div
                                    className={cx(
                                        'bottom-header-mobile__search bg-neutral-white',
                                        'tablet:absolute tablet:right-1 desktop:w-[calc(100%-140px)] max-w-[933px]',
                                        'tablet:h-full flex items-center tablet:max-desktop:w-[calc(100%-150px)]',
                                    )}
                                >
                                    <Autocomplete
                                        setValue={setValue}
                                        handleSearch={handleSearch}
                                        t={t}
                                        storeConfig={storeConfig}
                                        setIsSearchShown={setIsSearchShown}
                                        placeholder={t('common:search:title')}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div
                        className={cx(
                            'middle-header__statusicon',
                            'desktop:flex flex-row mobile:max-tablet:flex mobile:max-tablet:items-center',
                        )}
                    >
                        <div className={cx('middle-header__statusicon__left-section', 'flex', 'flex-row', 'justify-end items-center')}>
                            <div className="search-icon desktop:mr-[67.5px] tablet:max-[1200px]:!mr-6 desktop:mt-[-20px] desktop:ml-[6px]">
                                <Button
                                    className={cx(
                                        'mt-[10px]',
                                        '!px-0',
                                        '!py-0',
                                        'hover:shadow-none',
                                        'focus:shadow-none',
                                        'active:shadow-none',
                                        'active:shadow-none',
                                        'w-[24px]',
                                        'h-[23px]',
                                        'tablet:max-desktop:w-10',
                                        'tablet:max-desktop:h-[38px]',
                                        'tablet:max-desktop:mt-[3px]',
                                        'tablet:max-desktop:mr-5',
                                        'mobile:max-tablet:mt-0',
                                        isSearchShown ? 'invisible' : 'visible',
                                    )}
                                    onClick={() => setIsSearchShown(!isSearchShown)}
                                    icon={SearchIcon}
                                    iconProps={{
                                        className: cx(
                                            'text-neutral-700', 'w-[20px]', 'h-[20px]',
                                            'tablet:max-desktop:w-10',
                                            'tablet:max-desktop:h-[38px]',
                                            'tablet:max-desktop:before:!w-10 tablet:max-desktop:before:!h-[38px] tablet:max-desktop:before:!text-5xl',
                                            'tablet:max-desktop:before:!leading-[38px]',
                                        ),
                                    }}
                                    iconOnly
                                    variant="tertiary"
                                    classNameText={cx(
                                        '!text-neutral-700',
                                        'tablet:max-desktop:w-10',
                                        'tablet:max-desktop:h-[38px]',
                                    )}
                                />
                            </div>
                            {modules.productcompare.enabled && hideProductCompare && (
                                <div className={cx('product-compare', 'mobile:max-desktop:hidden', isZeroCompare ? '-ml-3' : '')}>
                                    <ProductCompareIcon setIsZeroCompare={setIsZeroCompare} showZeroValue={false} withLink isLogin={isLogin} />
                                </div>
                            )}
                            {/* <div className={cx('notification', 'hidden desktop:inline')}>
                                <NotificationBell withLink />
                            </div> */}

                            <div
                                className={cx(
                                    'middle-header__statusicon__right-section__account',
                                    'hover:cursor-pointer',
                                    'justify-start',
                                    'group',
                                    'hidden tablet:flex',
                                    'desktop:mt-[-2px]',
                                )}
                            >
                                <UserInfo
                                    withLabel={false}
                                    withUsername={!isHeaderV2}
                                    t={t}
                                    isLogin={isLogin}
                                    customer={customer}
                                    open={open}
                                    setOpen={setOpen}
                                    PopoverContent={PopoverContent}
                                    openState={openState}
                                    handleDispatch={dispatch}
                                    {...props}
                                />
                            </div>
                            <div
                                id="header-shoppingBag-icon"
                                className={cx(
                                    'shopping-bag desktop:ml-10 tablet:mr-[3px] tablet:max-[1200px]:!ml-6',
                                    'tablet:max-desktop:ml-5 tablet:max-desktop:mt-[2px]',
                                    'mobile:max-tablet:mt-1 mobile:max-tablet:-mr-[.5px] mobile:max-tablet:ml-[19.5px]',
                                )}
                            >
                                <ShoppingBagIcon
                                    openState={openState}
                                    handleDispatch={dispatch}
                                    withLink
                                    withLinkToCart
                                    storeConfig={storeConfig}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={cx(
                    'bottom-header',
                    'tablet:max-w-[768px]',
                    'desktop:max-w-[1280px]',
                    'm-[0_auto]',
                    'px-6',
                    'mobile:max-tablet:absolute',
                    'mobile:max-tablet:-top-[31px]',
                    'mobile:max-tablet:bg-neutral-white',
                    'mobile:max-tablet:w-full',
                    'mobile:max-tablet:px-2.5',
                )}
            >
                {isSearchShown && isMobile ? (
                    <div className={cx('bottom-header-mobile__search')}>
                        <Autocomplete
                            setValue={setValue}
                            handleSearch={handleSearch}
                            t={t}
                            storeConfig={storeConfig}
                            setIsSearchShown={setIsSearchShown}
                            placeholder={t('common:search:title')}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default DesktopHeader;
